import React, { useState } from "react";
import { CategoriesSlider } from "../../molecules/categories-slider/CategoriesSlider";
import styled from "styled-components";
import { SingleMeal } from "../../molecules/single-meal/SingleMeal";
import { P } from "../../atoms/paragraph/Paragraph.styled";
import { AnimadetPage } from "../../templates/animated-page/AnimadetPage";
import { useQuery } from "react-query";

import { motion } from "framer-motion";
import useScrollFromTop from "../../../hooks/useScrollFromTop";
import { GoToCartButton } from "../../molecules/go-to-cart-action/GoToCartButton";
import { useAppContext } from "../../../contexts/useAppContext";
import { LoadingScreen } from "../../templates/loading-screen/LoadingScreen";
import useScrollToTop from "../../../hooks/useScrollToTop";
import { useLocation, useNavigate } from "react-router-dom";
import { getProducts } from "../../../service";
import { Flex } from "antd";
import { GoToSummary } from "../../molecules/go-to-summary/GoToSummary";

const ListMealsWrapper = styled.div`
  padding-left: 8px;
  padding-right: 8px;
`;

const GridMealsWrapper = styled(Flex)``;

const MealsWrapper = ({
  viewType,
  children,
}: {
  viewType: "LIST" | "GRID";
  children: React.ReactNode;
}) => {
  switch (viewType) {
    case "LIST":
      return <ListMealsWrapper>{children}</ListMealsWrapper>;
    case "GRID":
      return (
        <GridMealsWrapper wrap="wrap" gap={8}>
          {children}
        </GridMealsWrapper>
      );
    default:
      return <ListMealsWrapper>{children}</ListMealsWrapper>;
  }
};

export const MenuPage: React.FC = () => {
  const navigate = useNavigate();

  useScrollToTop();
  const [preventAnimation, setPreventAnimation] = useState(false);
  const { scrollTop } = useScrollFromTop();
  const { cart, selectedCategory, selectedBranch } = useAppContext();
  const viewType = selectedBranch?.viewType ?? "LIST";

  const { data: categories = [], isFetching } = useQuery(
    ["/products", selectedBranch],
    () => getProducts(selectedBranch?.id),
    {
      enabled: !!selectedBranch,
    }
  );
  const animations = {
    initial: { opacity: 0, x: "10vw" },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0 },
  };

  if (!isFetching && categories) {
    return (
      <>
        <CategoriesSlider setPreventAnimation={setPreventAnimation} />
        <AnimadetPage>
          <div
            style={{
              paddingBottom: cart.length > 0 ? "100px" : "0px",
            }}
          >
            <motion.div
              variants={animations}
              initial="initial"
              animate="animate"
              exit="exit"
              style={{
                paddingTop:
                  scrollTop > 64 && !preventAnimation ? "45px" : "0px",
              }}
            >
              {categories
                .sort((a: any, b: any) => a.menuPosition - b.menuPosition)
                .map((category: any) => {
                  if (category.productBases?.length > 0)
                    return (
                      <>
                        <P
                          $size="18"
                          $height="24"
                          $weight="600"
                          style={{
                            paddingLeft: "12px",
                            paddingTop: "24px",
                            paddingBottom: category?.descripotion
                              ? "5px"
                              : "15px",
                          }}
                          id={category?.id}
                        >
                          {category?.name}
                        </P>
                        {category?.descripotion && (
                          <P
                            $size="14"
                            $weight="300"
                            $height="20"
                            $family="Lato-Light"
                            style={{
                              paddingLeft: "12px",
                              paddingTop: "5px",
                              paddingBottom: "26px",
                            }}
                            id={selectedCategory?.id}
                          >
                            {selectedCategory?.descripotion}
                          </P>
                        )}

                        <MealsWrapper viewType={viewType}>
                          {category?.productBases
                            ?.sort(
                              (a: any, b: any) =>
                                a.menuPosition - b.menuPosition
                            )
                            ?.map((meal: any) => {
                              return (
                                <SingleMeal
                                  key={meal.id}
                                  meal={meal}
                                  onClick={() => {
                                    navigate(`/details/${meal.id}`, {
                                      state: { from: "home" },
                                    });
                                  }}
                                />
                              );
                            })}
                        </MealsWrapper>
                      </>
                    );
                })}
            </motion.div>
          </div>
        </AnimadetPage>
        {cart.length > 0 && <GoToCartButton />}
      </>
    );
  }

  return <LoadingScreen />;
};
