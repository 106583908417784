import { Avatar, Col, Flex, Space } from "antd";
import React from "react";

import styled from "styled-components";
import { Labels } from "../labels/Labels";
import { P } from "../../atoms/paragraph/Paragraph.styled";
import test from "../../../images/meal.jpg";
import { useAppContext } from "../../../contexts/useAppContext";

const ListMeal = styled.div`
  border: 1px solid #f3f4f6;
  border-radius: 4px;
  margin-bottom: 10px;
  background: white;
  box-shadow: 1px 2px 0px 0px #edebfb;
  padding: 12px;
  cursor: pointer;
`;

const GridMeal = styled.div`
  border: 1px solid #f3f4f6;
  border-radius: 4px;
  margin-bottom: 10px;
  background: white;
  box-shadow: 1px 2px 0px 0px #edebfb;
  padding: 12px;
  cursor: pointer;
  width: calc(50% - 4px);

  @media (max-width: 700px) {
    width: 100%;
  }
`;

const Meal = ({
  viewType,
  children,
  onClick,
}: {
  viewType: "LIST" | "GRID";
  children: React.ReactNode;
  onClick: () => void;
}) => {
  switch (viewType) {
    case "LIST":
      return <ListMeal onClick={onClick}>{children}</ListMeal>;
    case "GRID":
      return <GridMeal onClick={onClick}>{children}</GridMeal>;
    default:
      return <ListMeal onClick={onClick}>{children}</ListMeal>;
  }
};

const ImageContainer = styled.div<{ type: "LIST" | "GRID" }>`
  width: ${(props) => (props.type === "GRID" ? "100%" : "140px")};
  height: ${(props) => (props.type === "GRID" ? "200px" : "140px")};
  min-width: ${(props) => (props.type === "GRID" ? null : "140px")};
  min-height: ${(props) => (props.type === "GRID" ? null : "140px")};
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &::before {
    content: "";
    display: block;
    padding-top: 100%; /* Ensures a square aspect ratio */
  }
`;

const StyledImage = styled.img<{ type: "LIST" | "GRID" }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; /* Takes 100% width */
  height: 100%; /* Matches the width in pixels */
  object-fit: cover; /* Ensures the image covers the entire area */
  border-radius: 4px;
`;

function truncateText(text: string, maxLength: number) {
  if (text.length > maxLength) {
    return text.slice(0, maxLength) + "...";
  }
  return text;
}

export const SingleMeal: React.FC<{ meal: any; onClick?: any }> = ({
  meal,
  onClick,
}) => {
  const { selectedBranch } = useAppContext();
  const viewType = selectedBranch?.viewType ?? "LIST";

  const price = meal.price ?? meal.basePrice;
  return (
    <Meal
      viewType={viewType}
      onClick={() => {
        onClick && onClick(meal);
      }}
    >
      <Flex
        justify="space-between"
        style={{
          marginBottom: viewType === "GRID" ? "16px" : "0px",
        }}
      >
        {viewType === "LIST" && (
          <Flex vertical justify="space-between">
            <div>
              <P
                $size="18"
                $height="24"
                $weight="400"
                style={{
                  marginBottom: "8px",
                }}
              >
                {meal.name}
              </P>
              <P $size="14" $height="20" $weight="300" $family="Lato-Light">
                {truncateText(meal?.description, 300)}
              </P>
            </div>{" "}
            <div>
              <P
                $size="18"
                $height="24"
                $weight="400"
                style={{
                  color: "#4f46e5",
                  margin: "0px",
                  whiteSpace: "nowrap",
                }}
              >
                {(price / 100).toFixed(2)} zł
              </P>
            </div>
          </Flex>
        )}
        <ImageContainer type={viewType}>
          <StyledImage src={meal.imageUrlS3} type={viewType} />
          <Labels labels={meal?.labels ?? []} />
        </ImageContainer>
      </Flex>
      {viewType === "GRID" && (
        <Flex
          justify="start"
          style={{
            marginBottom: "8px",
          }}
          vertical
          gap={5}
        >
          <P $size="18" $height="24" $weight="400">
            {meal.name}
          </P>
          <P $size="14" $height="20" $weight="300" $family="Lato-Light">
            {truncateText(meal?.description, 300)}
          </P>
        </Flex>
      )}
      {viewType === "GRID" && (
        <Flex justify={"start"}>
          <Space>
            <P
              $size="18"
              $height="24"
              $weight="400"
              style={{
                color: "#4f46e5",
                margin: "0px",
                whiteSpace: "nowrap",
              }}
            >
              {(price / 100).toFixed(2)} zł
            </P>
          </Space>
        </Flex>
      )}
    </Meal>
  );
};
