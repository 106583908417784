import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { P } from "../../atoms/paragraph/Paragraph.styled";
import { useQuery, useQueryClient } from "react-query";
import useScrollFromTop from "../../../hooks/useScrollFromTop";
import { scrollToElementById } from "../../../utils";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useAppContext } from "../../../contexts/useAppContext";
import { getProductCategories } from "../../../service";

const TopBar = styled.div`
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: center; /* center content horizontally */
`;

const ScrollableFixedBarWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-left: 2px;
  padding-right: 2px;
  background:white;
`;




export const CategoriesSlider: React.FC<{ setPreventAnimation: any }> = ({
  setPreventAnimation,
}) => {
  const { setSelectedCategory } = useAppContext();
  const { scrollTop } = useScrollFromTop();

  const { data: categories = [], isFetching } = useQuery(
    ["/product-categories"],
    () => getProductCategories(),
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      onSuccess(data) {
        setSelectedCategory(data?.[0]);
      },
    }
  );
  useEffect(() => {
    setPreventAnimation(!(categories?.length > 1));
  }, [categories]);
  useEffect(() => {
    const tabs = document.querySelectorAll(
      ".scrollable-tabs-container ul li a"
    )!;
    const rightArrow = document.querySelector(
      ".scrollable-tabs-container .right-arrow svg"
    )!;
    const leftArrow = document.querySelector(
      ".scrollable-tabs-container .left-arrow svg"
    )!;
    const tabsList = document.querySelector(".scrollable-tabs-container ul")!;
    const leftArrowContainer = document.querySelector(
      ".scrollable-tabs-container .left-arrow"
    )!;
    const rightArrowContainer = document.querySelector(
      ".scrollable-tabs-container .right-arrow"
    )!;

    const removeAllActiveClasses = () => {
      tabs.forEach((tab) => {
        tab.classList.remove("active");
      });
    };

    tabs.forEach((tab) => {
      tab.addEventListener("click", () => {
        removeAllActiveClasses();
        console.log("active", tab, tabs);

        tab.classList.add("active");
      });
    });

    const manageIcons = () => {
      if (tabsList.scrollLeft >= 20) {
        leftArrowContainer.classList.add("active");
      } else {
        leftArrowContainer.classList.remove("active");
      }

      let maxScrollValue = tabsList.scrollWidth - tabsList.clientWidth - 20;
      // console.log("scroll width: ", tabsList.scrollWidth);
      // console.log("client width: ", tabsList.clientWidth);

      if (tabsList?.scrollLeft >= maxScrollValue) {
        rightArrowContainer.classList.remove("active");
      } else {
        rightArrowContainer.classList.add("active");
      }
    };

    rightArrow?.addEventListener("click", () => {
      console.log("right");

      tabsList.scrollLeft += 200;
      manageIcons();
    });

    leftArrow?.addEventListener("click", () => {
      console.log("left");

      tabsList.scrollLeft -= 200;
      manageIcons();
    });

    tabsList?.addEventListener("scroll", manageIcons);


  }, [isFetching]);
  console.log('scrollTop',scrollTop)
  if (isFetching) return null;
  if (categories?.length > 1)
    return (
      <TopBar>
        <ScrollableFixedBarWrapper
          style={{
            position: scrollTop > 64 ? "fixed" : "relative",
            zIndex: 1000,
            top: 0,
            paddingTop: scrollTop > 64 ? "0px" : "2px",
          }}
        >
          <div className="scrollable-tabs-container">
            <div className="left-arrow">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-6 h-6"
                style={{
                  width: "35px",
                  height: "35px",
                }}
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M15.75 19.5L8.25 12l7.5-7.5"
                />
              </svg>
            </div>

            <ul>
              {categories
                .sort((a: any, b: any) => a.menuPosition - b.menuPosition)
                .map((element: any, index: number) => {
                  // if (element.productBases?.length > 0)
                    return (
                      <li
                        draggable="false"
                        onClick={() => {
                          setSelectedCategory(element);
                          scrollToElementById(element.id);
                        }}
                      >
                        <a className={index === 0 ? "active" : ""}>
                          {element.name}
                        </a>
                      </li>
                    );
                })}
            </ul>

            <div className="right-arrow active">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-6 h-6"
                style={{
                  width: "35px",
                  height: "35px",
                }}
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M8.25 4.5l7.5 7.5-7.5 7.5"
                />
              </svg>
            </div>
          </div>
        </ScrollableFixedBarWrapper>
      </TopBar>
    );

  return null;
};
