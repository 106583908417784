import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { P } from "../../atoms/paragraph/Paragraph.styled";
import { useQuery, useQueryClient } from "react-query";
import useScrollFromTop from "../../../hooks/useScrollFromTop";
import { scrollToElementById } from "../../../utils";
import { LeftOutlined, RightOutlined, SearchOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { getServices, getServicesCategory } from "../../../service";
import { useAppContext } from "../../../contexts/useAppContext";

const TopBar = styled.div`
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: center; /* center content horizontally */
`;

const ScrollableFixedBarWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-left: 2px;
  padding-right: 2px;

  // &:before {
  //   content: "";
  //   height: 100%;
  //   width: 10px;
  //   background-image: linear-gradient(
  //     90deg,
  //     rgba(255, 255, 255, 1),
  //     rgba(255, 255, 255, 0.5)
  //   );
  //   position: absolute;
  //   border-radius: 0 200% 200% 0;
  //   bottom: 0;
  //   left: 40px;
  //   z-index: 2000;
  // }

  // &:after {
  //   content: "";
  //   height: 100%;
  //   width: 10px;
  //   background-image: linear-gradient(
  //     90deg,
  //     rgba(255, 255, 255, 0.5),
  //     rgba(255, 255, 255, 1)
  //   );
  //   position: absolute;
  //   border-radius: 200% 0 0 200%;
  //   bottom: 0;
  //   right: 40px;
  //   z-index: 2000;
  // }
`;

const ScrollableFixedBar = styled.div<{ $fixed: any }>`
  z-index: 1000;

  width: 100%;
  max-width: 720px;
  overflow-x: auto;
  background-color: #fff !important;
  white-space: nowrap;
  scrollbar-width: none;
  position: relative;

  display: flex;
`;

const ScrollableContent = styled.div`
  display: flex;
  align-items: center;
  scrollbar-width: none;
`;

const Item = styled.div<{ $firstElement: boolean; $selected: boolean }>`
  padding-left: 15px;
  padding-right: 15px; /* top and bottom padding 4px, left and right padding 10px */
  display: inline-block;
  text-transform: uppercase;
  background-color: #fff; /* Your preferred item background color */
  /* Add more styling as needed */
  height: 48px;
  cursor: pointer;
  display: flex; /* use flexbox for layout */
  justify-content: center; /* center content horizontally */
  align-items: center; /* center content vertically */
  text-align: center;
  position: relative;

  &:after {
    content: "";
    height: 4px;
    width: 100%;
    background: ${({ $selected }) => ($selected ? "#000" : "#fff")};
    position: absolute;
    bottom: 8px;
    left: 0;
    z-index: 1000;
  }
`;

const ScrollRight = styled.div<{ $fixed: any }>`
  top: 0;
  right: 4px;
  width: 40px;
  height: 48px;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.9) 15%,
    rgba(255, 255, 255, 0.9) 100%
  );
  z-index: 2000;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ScrollLeft = styled.div<{ $fixed: any }>`
  top: 0;
  left: 4px;
  width: 40px;
  height: 48px;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.9) 0%,
    rgba(255, 255, 255, 0.9) 15%,
    rgba(255, 255, 255, 0) 100%
  );
  z-index: 2000;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Paragraph = styled(P)<{}>`
  position: relative;
`;

export const ServicesCategoriesSlider: React.FC<{
  setPreventAnimation: any;
}> = ({ setPreventAnimation }) => {
  const { setSelectedCategory } = useAppContext();
  const { scrollTop } = useScrollFromTop();

  const { data: categories = [], isFetching } = useQuery(
    ["/services-categories"],
    getServicesCategory,
    {
      enabled: !!true,
      onSuccess(data) {
        setSelectedCategory(data?.[0]);
      },
    }
  );

  useEffect(() => {
    setPreventAnimation(!(categories?.length > 1));
  }, [categories]);

  useEffect(() => {
    const tabs = document.querySelectorAll(".scrollable-tabs-container a")!;
    const rightArrow = document.querySelector(
      ".scrollable-tabs-container .right-arrow svg"
    )!;
    const leftArrow = document.querySelector(
      ".scrollable-tabs-container .left-arrow svg"
    )!;
    const tabsList = document.querySelector(".scrollable-tabs-container ul")!;
    const leftArrowContainer = document.querySelector(
      ".scrollable-tabs-container .left-arrow"
    )!;
    const rightArrowContainer = document.querySelector(
      ".scrollable-tabs-container .right-arrow"
    )!;

    const removeAllActiveClasses = () => {
      tabs.forEach((tab) => {
        tab.classList.remove("active");
      });
    };

    tabs.forEach((tab) => {
      tab.addEventListener("click", () => {
        removeAllActiveClasses();
        tab.classList.add("active");
      });
    });

    const manageIcons = () => {
      if (tabsList.scrollLeft >= 20) {
        leftArrowContainer.classList.add("active");
      } else {
        leftArrowContainer.classList.remove("active");
      }

      let maxScrollValue = tabsList.scrollWidth - tabsList.clientWidth - 20;
      // console.log("scroll width: ", tabsList.scrollWidth);
      // console.log("client width: ", tabsList.clientWidth);

      if (tabsList?.scrollLeft >= maxScrollValue) {
        rightArrowContainer.classList.remove("active");
      } else {
        rightArrowContainer.classList.add("active");
      }
    };

    rightArrow?.addEventListener("click", () => {
      console.log('right')
      tabsList.scrollLeft += 200;
      manageIcons();
    });

    leftArrow?.addEventListener("click", () => {
      console.log('left')
      tabsList.scrollLeft -= 200;
      manageIcons();
    });

    tabsList?.addEventListener("scroll", manageIcons);

    // const [dragging, setDragging] = useState(false);
    // const drag = (e: any) => {
    //   if (!dragging) return;
    //   tabsList.classList.add("dragging");
    //   tabsList.scrollLeft -= e.movementX / 4;
    // };

    // tabsList?.addEventListener("mousedown", () => {
    //   setDragging(true)
    // });

    // tabsList?.addEventListener("mousemove", drag);

    // document.addEventListener("mouseup", () => {
    //   setDragging(false)
    //   tabsList?.classList.remove("dragging");
    // });
  }, [isFetching]);

  if (isFetching) return null;
  if (categories?.length > 1)
    return (
      <TopBar>
        <ScrollableFixedBarWrapper
          style={{
            position: scrollTop > 64 ? "fixed" : "relative",
            zIndex: 1000,
            top: 0,
            paddingTop: scrollTop > 64 ? "0px" : "2px",
          }}
        >
          <div className="scrollable-tabs-container">
            <div className="left-arrow">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M15.75 19.5L8.25 12l7.5-7.5"
                />
              </svg>
            </div>

            <ul>
              {categories.map((element: any, index: number) => {
                return (
                  <li
                    draggable="false"
                    onClick={() => {
                      setSelectedCategory(element);
                      scrollToElementById(element.id);
                    }}
                  >
                    <a className={index === 0 ? "active" : ""}>
                      {element.name}
                    </a>
                  </li>
                );
              })}
            </ul>

            <div className="right-arrow active">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M8.25 4.5l7.5 7.5-7.5 7.5"
                />
              </svg>
            </div>
          </div>
        </ScrollableFixedBarWrapper>
      </TopBar>
    );

  return null;
};
