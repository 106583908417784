import { Button, Flex } from "antd";
import React from "react";
import styled from "styled-components";
import { P } from "../../atoms/paragraph/Paragraph.styled";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppContext } from "../../../contexts/useAppContext";

const GoToCartButtonWrapper = styled.div`
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  background: #f3f4f6;
  border-top: 1px solid #e9eaee;
  display: flex;
  justify-content: center;
`;

const CostumAddButton = styled(Button)`
  max-width: 100% !important;
  width: 100% !important;
  height: 40px !important;
  font-size: 18px;
  border-radius: 40px;
  color: white;
  background: #4f46e5;
  border-color: #4f46e5;

  &:hover {
    color: white !important;
    border-color: #4f46e5 !important;
  }
`;

export const GoToCartButton: React.FC = () => {
  const navigate = useNavigate();
  // const location = useLocation();
  const { cart } = useAppContext();
  // const isOrder = !!location?.state?.isOrder;

  return (
    <GoToCartButtonWrapper>
      <Flex
        justify="space-between"
        gap={8}
        style={{
          maxWidth: "450px",
          width: "100%",
        }}
      >
        <CostumAddButton
          onClick={() => navigate("/cart", { state: { from: "home" } })}
        >
          <P $size="16" $height="20" $weight="400">
            Koszyk ({cart.length})
          </P>
        </CostumAddButton>
        {/* <CostumAddButton
          onClick={() =>
            navigate("/delivery", { state: { from: "home", isOrder: isOrder } })
          }
        >
          <P $size="16" $height="20" $weight="400">
            Dostawa
          </P>
        </CostumAddButton> */}
      </Flex>
    </GoToCartButtonWrapper>
  );
};
