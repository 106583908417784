import React, { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { ProductDetails } from "../../pages/dish-details-page/ProductDetails";
import { MenuPage } from "../../pages/menu-page/MenuPage";
import { AnimatePresence } from "framer-motion";
import { CartPage } from "../../pages/cart-page/CartPage";
import { DeliveryPage } from "../../pages/delivery-page/DeliveryPage";
import { BranchesPage } from "../../pages/branches-page/BranchesPage";
import { StatutePage } from "../../pages/statute-page/StatutePage";
import { SummaryPage } from "../../pages/summary-page/SummaryPage";
import { ServiceDataPicker } from "../../pages/service-data-pickes-page/ServiceDataPicker";
import { ServicesPage } from "../../pages/services-page/ServicesPage";
import { ThankYouPage } from "../../pages/thank-you/ThankYouPage";
import { OrderDataPicker } from "../../pages/order-data-pickes-page/OrderDataPicker";
import { SelectResource } from "../../pages/select-resource-page/SelectResource";
import { ToPayPage } from "../../pages/to-pay/ToPayPage";

export const AppContent: React.FC = () => {
  const location = useLocation();

  return (
    <AnimatePresence mode="wait">
      <Routes key={location.pathname} location={location}>
        <Route path="/" element={<BranchesPage />} />
        <Route path="/statute" element={<StatutePage />} />
        <Route path="/menu" element={<MenuPage />} />
        <Route path="/details/:id" element={<ProductDetails />} />
        <Route path="/reservate/:id" element={<ServiceDataPicker />} />
        <Route path="/delivery-date" element={<OrderDataPicker />} />
        <Route path="/cart" element={<CartPage />} />
        <Route path="/delivery" element={<DeliveryPage />} />
        <Route path="/summary" element={<SummaryPage />} />
        <Route path="/services" element={<ServicesPage />} />
        <Route path="/resources" element={<SelectResource />} />
        <Route path="/thank-you" element={<ThankYouPage />} />
        <Route path="/to-pay" element={<ToPayPage />} />
      </Routes>
    </AnimatePresence>
  );
};
