import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { AnimadetPage } from "../../templates/animated-page/AnimadetPage";
import { Avatar, Flex, Space } from "antd";

import { User, Package, Receipt, Wallet } from "@phosphor-icons/react";

import { P } from "../../atoms/paragraph/Paragraph.styled";
import { useQuery } from "react-query";
import { getCompanyPayments } from "../../../service";

import { useAppContext } from "../../../contexts/useAppContext";
import { AdditionPrice } from "../../atoms/addition/Addition";
import { GoToPay } from "../../molecules/go-to-pay/GoToPay";
import useScrollToTop from "../../../hooks/useScrollToTop";
import { useLocation } from "react-router-dom";
import { PaymentOption } from "../../atoms/payment-option/PaymentOption";

const MealsWrapper = styled.div`
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 30px;
  min-height: calc(100vh + 20px);
`;

const Meal = styled.div`
  border: 1px solid #f3f4f6;
  border-radius: 8px;
  margin-bottom: 10px;
  background: white;
  box-shadow: 1px 2px 0px 0px #edebfb;
  padding: 16px;
`;

export const SummaryPage: React.FC = () => {
  useScrollToTop();
  const {
    cart,
    additionalSections,
    deliveryType,
    deliveryAdress,
    deliveryTimeType,
    orderDate,
    orderTime,
    apertmenNumber,
    deliveryNote,
    serviceTime,
    serviceData,
    number,
    name,
    email,
    selectedBranch,
    selectedDeliveryMethod,
  } = useAppContext();
  const location = useLocation();
  const isOrder = !!location?.state?.isOrder;
  const isShop = selectedBranch.sellType === "DIGITAL_PRODUCTS_SELLER";

  const total_ammount = useMemo(
    () =>
      cart
        .map(
          (position: any) =>
            position.cart_positoin.meal_price *
            position.cart_positoin.meal_quantity
        )
        .reduce((accumulator: any, currentValue: any) => {
          return accumulator + currentValue;
        }, 0),
    [cart]
  );
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState<any>();

  const selectedOptionObject = options.find(
    (e: any) => e.id === selectedOptions?.id
  );

  const { isFetching } = useQuery(["/payment_methods"], getCompanyPayments, {
    onSuccess(data) {
      setOptions(data);
      if (data?.length === 1) {
        setSelectedOptions(data[0]);
      }
      if (data?.length > 1) {
        const onlineOption = data.find((e: any) => e.type === "PRZELEWY24");
        if (onlineOption) {
          setSelectedOptions(onlineOption);
        }
      }
    },
  });

  if (isFetching) return null;

  return (
    <>
      <AnimadetPage>
        <MealsWrapper>
          <Meal>
            <Space
              style={{
                marginBottom: "25px",
              }}
            >
              <Avatar
                shape="square"
                style={{
                  background: "#eef2ff",
                  paddingTop: "4px",
                }}
                src={<Package size={20} color="#4f46e5" />}
              />
              <P $size="16" $weight="400" $height="20">
                {isOrder ? "Podsumowanie zamówienia" : "Rezerwujesz"}
              </P>
            </Space>

            {cart.map((element: any, index: number) => {
              return (
                <Flex
                  vertical
                  style={{
                    marginBottom: "5px",
                  }}
                >
                  <Space
                    style={{
                      marginBottom: "5px",
                    }}
                  >
                    <P $size="16" $height="20" $weight="400">
                      {`${index + 1}. (${
                        element.cart_positoin.meal_quantity
                      }x) ${element.cart_positoin.meal_name}`}
                    </P>
                  </Space>

                  {element.cart_positoin.meal_options.map(
                    ({ controls }: any) => {
                      return controls.map((control: any) => {
                        return (
                          <>
                            <Space
                              style={{
                                columnGap: "4px",
                                paddingLeft: "5px",
                              }}
                            >
                              <span>
                                <P
                                  $size="14"
                                  $height="16"
                                  $weight="400"
                                  $family="Lato-Light"
                                >
                                  {`- ${control.name}: ${control.value}`}
                                </P>
                              </span>
                            </Space>
                          </>
                        );
                      });
                    }
                  )}
                  {element.cart_positoin?.description && (
                    <Space>
                      <P
                        $size="14"
                        $height="20"
                        $weight="200"
                        $family="Lato-Light"
                      >
                        Komentarz: {element.cart_positoin?.description}
                      </P>
                    </Space>
                  )}
                </Flex>
              );
            })}
          </Meal>
          <Meal>
            <Space
              style={{
                marginBottom: "25px",
              }}
            >
              <Avatar
                shape="square"
                style={{
                  background: "#eef2ff",
                  paddingTop: "4px",
                }}
                src={<User size={20} color="#4f46e5" />}
              />
              <P $size="16" $weight="400" $height="20">
                {isOrder ? "Szczegóły zamówienia" : "Szczegóły rezerwacji"}
              </P>
            </Space>
            <Flex
              vertical
              style={{
                marginBottom: "20px",
              }}
            >
              <>
                <Space
                  style={{
                    columnGap: "4px",
                  }}
                >
                  <span>
                    <P $size="14" $height="16" $weight="400" $family="Lato">
                      Email:
                    </P>
                  </span>
                  <span>
                    <P
                      $size="14"
                      $height="20"
                      $weight="300"
                      $family="Lato-Light"
                    >
                      {email}
                    </P>
                  </span>
                </Space>
                <Space
                  style={{
                    columnGap: "4px",
                  }}
                >
                  <span>
                    <P $size="14" $height="16" $weight="400" $family="Lato">
                      Imie i nazwisko:
                    </P>
                  </span>
                  <span>
                    <P
                      $size="14"
                      $height="20"
                      $weight="300"
                      $family="Lato-Light"
                    >
                      {name}
                    </P>
                  </span>
                </Space>
                <Space
                  style={{
                    columnGap: "4px",
                    marginBottom: "10px",
                  }}
                >
                  <span>
                    <P $size="14" $height="16" $weight="400" $family="Lato">
                      Numer telefonu:
                    </P>
                  </span>
                  <span>
                    <P
                      $size="14"
                      $height="20"
                      $weight="300"
                      $family="Lato-Light"
                    >
                      {number}
                    </P>
                  </span>
                </Space>
              </>
              {!isShop ? (
                isOrder ? (
                  <>
                    <Space
                      style={{
                        columnGap: "4px",
                      }}
                    >
                      <span>
                        <P $size="14" $height="16" $weight="400" $family="Lato">
                          Rodzaj dostawy:
                        </P>
                      </span>
                      <span>
                        <P
                          $size="14"
                          $height="20"
                          $weight="300"
                          $family="Lato-Light"
                        >
                          {selectedDeliveryMethod?.name}
                        </P>
                      </span>
                    </Space>
                    {deliveryType === "TO_THE_ADDRESS" && (
                      <>
                        <Space
                          style={{
                            columnGap: "4px",
                          }}
                        >
                          <span>
                            <P
                              $size="14"
                              $height="16"
                              $weight="400"
                              $family="Lato"
                            >
                              Adres dostawy:
                            </P>
                          </span>
                          <span>
                            <P
                              $size="14"
                              $height="20"
                              $weight="300"
                              $family="Lato-Light"
                            >
                              {deliveryAdress?.label ?? ""}
                            </P>
                          </span>
                        </Space>
                        {apertmenNumber?.length > 0 && (
                          <Space
                            style={{
                              columnGap: "4px",
                            }}
                          >
                            <span>
                              <P
                                $size="14"
                                $height="16"
                                $weight="400"
                                $family="Lato"
                              >
                                Numer mieszkania:
                              </P>
                            </span>
                            <span>
                              <P
                                $size="14"
                                $height="20"
                                $weight="300"
                                $family="Lato-Light"
                              >
                                {apertmenNumber}
                              </P>
                            </span>
                          </Space>
                        )}
                        {deliveryNote?.length > 0 && (
                          <Space
                            style={{
                              columnGap: "4px",
                            }}
                          >
                            <span>
                              <P
                                $size="14"
                                $height="16"
                                $weight="400"
                                $family="Lato"
                              >
                                Dodatkowe informacje:
                              </P>
                            </span>
                            <span>
                              <P
                                $size="14"
                                $height="20"
                                $weight="300"
                                $family="Lato-Light"
                              >
                                {deliveryNote}
                              </P>
                            </span>
                          </Space>
                        )}
                      </>
                    )}
                    <Space
                      style={{
                        columnGap: "4px",
                      }}
                    >
                      <span>
                        <P $size="14" $height="16" $weight="400" $family="Lato">
                          {deliveryType === "TO_THE_ADDRESS"
                            ? "Termin dostawy:"
                            : "Termin odbioru:"}
                        </P>
                      </span>
                      <span>
                        <P
                          $size="14"
                          $height="20"
                          $weight="300"
                          $family="Lato-Light"
                        >
                          {deliveryTimeType === "NOW"
                            ? "Jak najszybciej"
                            : `${orderDate} ${orderTime}`}
                        </P>
                      </span>
                    </Space>
                  </>
                ) : (
                  <>
                    <Space
                      style={{
                        columnGap: "4px",
                      }}
                    >
                      <span>
                        <P $size="14" $height="16" $weight="400" $family="Lato">
                          Termin realizacji:
                        </P>
                      </span>
                      <span>
                        <P
                          $size="14"
                          $height="20"
                          $weight="300"
                          $family="Lato-Light"
                        >
                          {`${serviceData} ${serviceTime}`}
                        </P>
                      </span>
                    </Space>
                  </>
                )
              ) : null}
            </Flex>
          </Meal>
          {additionalSections.map(({ sectionName, controls }: any) => {
            return (
              <Meal>
                <Space
                  style={{
                    marginBottom: "25px",
                  }}
                >
                  <Avatar
                    shape="square"
                    style={{
                      background: "#eef2ff",
                      paddingTop: "4px",
                    }}
                    src={<Receipt size={20} color="#4f46e5" />}
                  />
                  <P $size="16" $weight="400" $height="20">
                    {sectionName}
                  </P>
                </Space>

                <Flex
                  vertical
                  style={{
                    marginBottom: "20px",
                  }}
                >
                  {controls.map((control: any) => {
                    return (
                      <Space
                        style={{
                          columnGap: "4px",
                        }}
                      >
                        <span>
                          <P
                            $size="14"
                            $height="16"
                            $weight="400"
                            $family="Lato"
                          >
                            {control.name}:
                          </P>
                        </span>
                        <span>
                          <P
                            $size="14"
                            $height="20"
                            $weight="300"
                            $family="Lato-Light"
                          >
                            {control.value}
                          </P>
                        </span>
                      </Space>
                    );
                  })}
                </Flex>
              </Meal>
            );
          })}

          <Meal>
            <Space
              style={{
                marginBottom: "25px",
              }}
            >
              <Avatar
                shape="square"
                style={{
                  background: "#eef2ff",
                  paddingTop: "4px",
                }}
                src={<Wallet size={20} color="#4f46e5" />}
              />
              <P $size="16" $weight="400" $height="20">
                Sposób zapłaty
              </P>
            </Space>

            <Flex
              justify="start"
              align="center"
              gap={12}
              style={{
                marginBottom: "30px",
              }}
            >
              {options
                .filter((e: any) => {
                  return !isShop || e?.type === "PRZELEWY24";
                })
                .map((e) => {
                  return (
                    <PaymentOption
                      option={e}
                      selectedOptions={selectedOptions}
                      setSelectedOptions={setSelectedOptions}
                    />
                  );
                })}
            </Flex>
            <Flex
              justify="space-between"
              align="center"
              style={{
                marginBottom: "5px",
              }}
            >
              <P $size="16" $height="20" $weight="400" $family="Lato-Light">
                Podsumowanie
              </P>
              <P $size="16" $height="20" $weight="400" $family="Lato-Light">
                <AdditionPrice price={total_ammount} />
              </P>
            </Flex>
            {isOrder && (
              <Flex
                justify="space-between"
                align="center"
                style={{
                  marginBottom: "5px",
                }}
              >
                <P $size="16" $height="20" $weight="400" $family="Lato-Light">
                  Dostawa
                </P>
                <P $size="16" $height="20" $weight="400" $family="Lato-Light">
                  <AdditionPrice price={deliveryAdress?.price ?? 0} />
                </P>
              </Flex>
            )}
            <Flex justify="space-between" align="center">
              <P $size="18" $height="26" $weight="600">
                Do zapłaty
              </P>
              <P $size="18" $height="26" $weight="600">
                <AdditionPrice
                  price={total_ammount + (deliveryAdress?.price ?? 0)}
                />
              </P>
            </Flex>
          </Meal>
        </MealsWrapper>
      </AnimadetPage>
      <GoToPay selectedOptionObject={selectedOptionObject} />
    </>
  );
};
