import { Flex, Form, Input, Select } from "antd";
import "./index.css";
import { motion } from "framer-motion";
import { P } from "../paragraph/Paragraph.styled";
import { useAppContext } from "../../../contexts/useAppContext";
import { GoogleMaps } from "../google-maps/GoogleMaps";
import { DeliveryTimePicker } from "../delivery-time-picker/DeliveryTimePicker";
import { useLocation } from "react-router-dom";
import { useQuery } from "react-query";
import { getDeliveryMethods } from "../../../service";
import { useEffect, useState } from "react";
import { ChangeReservationDate } from "../change-reservation-date/ChangeReservationDate";
import { ChangeResources } from "../change-resources/ChangeResources";
import styled from "styled-components";

export const StyledCheckbox = styled.input.attrs({ type: "checkbox" })<{
  $disabled: boolean;
}>`
  -webkit-appearance: none;
  position: relative;
  background-color: #f3f4f6;
  width: 350px;
  max-width: 350px;
  height: 40px;
  border-radius: 20px;
  transition: all 0.7s ease;

  &::before {
    content: "";
    position: absolute;
    left: -17px;
    width: 225px;
    height: 40px;
    background-color: ${(props) => (props.$disabled ? "gray" : "#4f46e5")};
    border-radius: 20px;
    transform: scale(0.8);
    transition: all 0.7s ease;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
  }

  &:checked::before {
    left: 143px;
  }
`;

export const Toggle = () => {
  const {
    deliveryType,
    setDeliveryType,
    selectedBranch,
    selectedDeliveryMethod,
    setSelectedDeliveryMethod,
    name,
    setName,
    email,
    setEmail,
    number,
    setNumber,
    setDeliveryAdress,
  } = useAppContext();
  const location = useLocation();
  const isOrder = !!location?.state?.isOrder;
  const isShop = selectedBranch.sellType === "DIGITAL_PRODUCTS_SELLER";

  const id = selectedBranch?.id;
  const [options, setOptions] = useState([]);
  const [allOptions, setAllOptions] = useState([]);
  const [onlyOneType, setOnlyOneType] = useState(false);

  useQuery(["/delivery-methods", id], () => getDeliveryMethods({ id }), {
    onSuccess(data) {
      setAllOptions(data);
      if (!isShop) {
        const opt = data
          .filter((e: any) => e.type === deliveryType)
          .map((e: any) => {
            return {
              value: e.id,
              label: e.name,
            };
          });
        setOptions(opt);
        if (opt?.length === 1) {
          const option = data.find(
            (e: any) => e.id.toString() === opt[0]?.value.toString()
          );
          setSelectedDeliveryMethod(option);
          option?.type && setDeliveryType(option.type);
        } else if (data?.length === 1) {
          setSelectedDeliveryMethod(data[0]);
          data[0]?.type && setDeliveryType(data[0].type);
          setOnlyOneType(true);
        } else {
          setSelectedDeliveryMethod(null);
        }

        if (data?.length === 1) {
          setOnlyOneType(true);
        }
      } else {
        const opt = data.find((e: any) => e.type === "EMAIL");
        setSelectedDeliveryMethod(opt);
      }
    },
    enabled: !!id && isOrder,
  });

  useEffect(() => {
    if (!isShop) {
      const opt: any = allOptions
        .filter((e: any) => e.type === deliveryType)
        .map((e: any) => {
          return {
            value: e.id,
            label: e.name,
          };
        });
      setOptions(opt);
      if (opt?.length === 1) {
        const option: any = allOptions.find(
          (e: any) => e.id.toString() === opt[0]?.value.toString()
        );
        setSelectedDeliveryMethod(option);
        option?.type && setDeliveryType(option.type);
      } else if (allOptions?.length === 1) {
        setSelectedDeliveryMethod(allOptions[0]);
        (allOptions[0] as any)?.type &&
          setDeliveryType((allOptions[0] as any).type);
        setOnlyOneType(true);
      } else {
        setSelectedDeliveryMethod(null);
      }
    }
  }, [deliveryType]);

  const onSelect = (value: string | number) => {
    const options = allOptions.find(
      (e: any) => e.id.toString() === value.toString()
    );
    setSelectedDeliveryMethod(options);
  };
  useEffect(() => {
    if (isShop) {
      setDeliveryType("EMAIL");
    }
  }, [isShop]);

  useEffect(() => {
    if (deliveryType === "EMAIL" || deliveryType === "SELF_PICKUP") {
      setDeliveryAdress(null);
    }
  }, [deliveryType]);
  // if (allOptions?.length > 0)

  const textVariants = {
    initial: { color: "#f3f4f6" }, // Initial color (e.g., black)
    animate: {
      color: deliveryType === "SELF_PICKUP" && onlyOneType ? "gray" : "#4f46e5", // New color (e.g., red)
      transition: { duration: 1 }, // Duration of the animation in seconds
    },
  };

  const textVariants2 = {
    initial: {
      color:
        deliveryType === "TO_THE_ADDRESS" && onlyOneType ? "gray" : "#4f46e5",
    }, // Initial color (e.g., black)
    animate: {
      color: "#f3f4f6", // New color (e.g., red)
      transition: { duration: 1 }, // Duration of the animation in seconds
    },
  };

  return (
    <>
      {isOrder && !isShop && (
        <>
          <Flex justify="center" vertical align="center">
            <div
              style={{
                position: "relative",
              }}
            >
              <label
                htmlFor="delivery-type"
                style={{
                  position: "absolute",
                  top: "15px",
                  left: "73px",
                  zIndex: 1000,
                }}
              >
                <motion.div
                  initial="initial"
                  animate={
                    deliveryType === "SELF_PICKUP" ? "animate" : "initial"
                  }
                  variants={textVariants}
                >
                  <P $size="11" $weight="400" $height="16">
                    Dostawa
                  </P>
                </motion.div>
              </label>
              <label
                htmlFor="delivery-type"
                style={{
                  position: "absolute",
                  top: "15px",
                  right: "50px",
                  zIndex: 1000,
                }}
              >
                <motion.div
                  initial="initial"
                  animate={
                    deliveryType === "SELF_PICKUP" ? "animate" : "initial"
                  }
                  variants={textVariants2}
                >
                  <P $size="11" $weight="400" $height="16">
                    Odbiór osobisty
                  </P>
                </motion.div>
              </label>
              <StyledCheckbox
                $disabled={onlyOneType}
                disabled={onlyOneType}
                type="checkbox"
                id="delivery-type"
                checked={deliveryType === "SELF_PICKUP"}
                onChange={(e) => {
                  if (!onlyOneType)
                    setDeliveryType(
                      deliveryType === "SELF_PICKUP"
                        ? "TO_THE_ADDRESS"
                        : "SELF_PICKUP"
                    );
                }}
              />
            </div>
            {onlyOneType && (
              <P
                $size="11"
                $weight="400"
                $height="16"
                style={{
                  color: "red",
                }}
              >
                {deliveryType === "SELF_PICKUP"
                  ? "Dostawa obecnie niedostępna"
                  : "Odbiór osobisty obecnie niedostępny"}
              </P>
            )}
          </Flex>
          <Form layout="vertical">
            {options.length > 1 && (
              <Form.Item
                label={
                  deliveryType === "SELF_PICKUP"
                    ? "Opcje dostawy "
                    : "Opcje odbioru "
                }
              >
                <Select
                  options={options}
                  value={selectedDeliveryMethod?.id}
                  onSelect={onSelect}
                  placeholder="Wybierz opcję"
                />
              </Form.Item>
            )}
          </Form>
        </>
      )}
      {isOrder && !isShop && deliveryType === "TO_THE_ADDRESS" && (
        <>
          <GoogleMaps />
          <hr
            style={{
              marginBottom: "20px",
              width: "95%",
              background: "#e5e7eb",
              color: "#e5e7eb",
            }}
          />
        </>
      )}
      <Form layout="vertical">
        <Form.Item label="Imie i nazwisko" required>
          <Input
            style={{
              height: "40px",
            }}
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Wpisz swoje imie i nazwisko"
          />
        </Form.Item>
      </Form>
      <Form layout="vertical">
        <Form.Item label="E-mail" required>
          <Input
            style={{
              height: "40px",
            }}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="np. adamnowak@gmail.com"
          />
        </Form.Item>
      </Form>
      <Form layout="vertical">
        <Form.Item label="Numer telefonu" required>
          <Input
            value={number}
            onChange={(e) => setNumber(e.target.value)}
            style={{
              height: "40px",
            }}
            placeholder="Wpisz swój numer telefonu"
          />
        </Form.Item>
      </Form>
      {!isShop && (
        <hr
          style={{
            marginBottom: "20px",
            width: "95%",
            background: "#e5e7eb",
            color: "#e5e7eb",
          }}
        />
      )}

      {isOrder && !isShop && <DeliveryTimePicker />}
      {!isOrder && (
        <Form layout="vertical">
          <ChangeReservationDate
            control={{
              uuid: "date",
              field_name: "Termin",
              required: true,
            }}
          />
          <ChangeResources
            control={{
              uuid: "date",
              field_name: "Termin",
              required: true,
            }}
          />
        </Form>
      )}
    </>
  );
};
