/* eslint-disable no-restricted-globals */

import { Layout } from "antd";
import { AppContent } from "./components/templates/app-content/AppContent";
import { Header } from "./components/organisms/header/Header";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppContext } from "./contexts/useAppContext";
import { useLoadScript } from "@react-google-maps/api";
import { Footer } from "./components/organisms/footer/Footer";
import { useDarkMode } from "./hooks/useDarkMode";

const { Content } = Layout;

const googleMapsApiKey = "AIzaSyA3lh6-T9qUYkdo46jFI1jaNQ_z2JNdCEs";
const libraries: ("places" | "geometry")[] = ["geometry", "places"];
function App() {
  const mode = useDarkMode();
  console.log("mode-mode", mode);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: googleMapsApiKey,
    libraries,
  });

  const navigate = useNavigate();
  const location = useLocation();
  const { selectedBranch } = useAppContext();
  const [showPage, setShowPage] = useState(false);

  useEffect(() => {
    if (location.pathname !== "/" && !selectedBranch) {
      navigate("/");
    } else {
      setShowPage(true);
    }
  }, [navigate, location]);

  if (showPage && isLoaded)
    return (
      <Layout
        style={{
          minHeight: "100vh",
          background: "white",
        }}
      >
        <Header />

        <Content>
          <AppContent />
        </Content>
        {location.pathname !== "/" && <Footer />}
      </Layout>
    );

  return null;
}
export default App;
